@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans 3", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

.main-dashboard {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.main-box {
  margin: 25px 0 0 0;
}

.main-box-detail {
  margin-top: 25px;
  padding: 0 30px;
  display: flex;
}

.main-box-graph {
  height: 220px;
  width: 105%;
  /* margin: "-30px -20px 0 0" */
}

.det-graph-data {
  width: 200%;
  margin: 30px 0;
  padding: 20px;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #555;
}