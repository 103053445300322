@import url("https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.css");

body, html {
    margin: 0;
    padding: 0;
    height: 100vh;
    font-family: ArtifaktElement; /* Will be added by Forge Viewer */
}

#preview {
    position: absolute;
    height: 730px;
    width: 800px;
}

#overlay {
    position: absolute;
    width: 750px;
    height: 730px;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1em;
    display: none;
}

#overlay > .notification {
    margin: auto;
    padding: 1em;
    max-width: 50%;
    background: white;
}

#WindowControl {
    background-image: url('./img/Industry-Robot-icon.png');
    background-size: 36px;
    background-repeat: no-repeat;
    background-position: center;
}

#windowControlPanelContainer {
    background-color: white;
    border: solid 1px gray;
    bottom: 10px;
    right: 10px;
    width: 300px;
    height: 200px;
    resize: none;
}

#controlsArea {
    padding: 5px 10px;
}

.wing-reset {
    margin: 5px 0;
}